import React from 'react';
import { Link } from 'gatsby';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';

import t from '../../../lib/translate';
import { useLogin } from '../../../lib/service/account/useLogin';
import {
  LoginAlreadyLoggedinError,
  LoginUnauthorizedError,
} from '../../../lib/service/account/api/errors';

import LoadingButton from '../../LoadingButton';

import * as styles from './component.module.scss';

const loginErrMsg = (err) => {
  if (err instanceof LoginAlreadyLoggedinError) {
    return t`account_err_already_logged_in`;
  }

  if (err instanceof LoginUnauthorizedError) {
    return t`account_err_logged_incorrect`;
  }

  return t`account_err_login_failed`;
};

const ShopAccountLoginForm = ({
  namespace = '',
  onLoggedIn = () => {},
  onInputFocus = () => {},
  onInputBlur = () => {},
  theme = '',
}) => {
  const queryClient = useQueryClient();
  const login = useLogin(queryClient, onLoggedIn);

  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      validationSchema={Yup.object({
        username: Yup.string().email(t`validation_err_email`).required(t`validation_err_required`),
        password: Yup.string().required(t`validation_err_required`),
      })}
      onSubmit={async (values) => login.mutate(values)}
    >
      <Form className="customer-mini-login">
        <div className="form-group">
          <label htmlFor={`${namespace}mini-login`} className="sr-only">
            {t`account_header_login_email`}
          </label>
          <Field
            type="email"
            autoComplete="username"
            autoCapitalize="off"
            autoCorrect="off"
            spellCheck="false"
            name="username"
            id={`${namespace}mini-login`}
            className={`form-control form-control-${theme}`}
            placeholder={t`account_header_login_email`}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
          />
          <ErrorMessage name="username">
            { (msg) => <span>{msg}</span>}
          </ErrorMessage>
        </div>

        <div className="form-group">
          <label htmlFor={`${namespace}mini-password`} className="sr-only">
            {t`account_header_login_password`}
          </label>
          <Field
            type="password"
            autoComplete="current-password"
            name="password"
            id={`${namespace}mini-password`}
            className={`form-control form-control-${theme}`}
            placeholder={t`account_header_login_password`}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
          />
          <ErrorMessage name="password">
            { (msg) => <span>{msg}</span>}
          </ErrorMessage>
        </div>
        <div className={styles.btnGroup}>
          <LoadingButton
            className="btn-primary"
            type="submit"
            isLoading={login.isLoading}
            isSuccess={login.isSuccess}
            isError={login.isError}
          >
            {t`account_header_login_submit_label`}
          </LoadingButton>

          <Link to="/customer/account/forgotpassword" className="link-reset-password small">
            {t`account_header_resetpassword_button_label`}
          </Link>
        </div>

        {login.isError && (
          <span>
            {loginErrMsg(login.error)}
          </span>
        )}
      </Form>
    </Formik>
  );
};

ShopAccountLoginForm.defaultProps = {
  namespace: '',
  onLoggedIn: () => {},
  onInputFocus: () => {},
  onInputBlur: () => {},
  theme: 'dark',
};

ShopAccountLoginForm.propTypes = {
  namespace: PropTypes.string,
  onLoggedIn: PropTypes.func,
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
  theme: PropTypes.string,
};

export default ShopAccountLoginForm;
