import { useMutation } from 'react-query';

import { login } from './api/login';
import { useAccountIdQueryKey } from './useAccountId';
import { useWishlistQueryKey } from '../wishlist/useWishlist';
import { useCartQueryKey } from '../cart/useCart';

// eslint-disable-next-line max-len
const useLogin = (queryClient, onSettled) => useMutation(({ username, password }) => login(username, password), {
  retry: false,
  onMutate: async () => {
    await queryClient.cancelQueries(useCartQueryKey);
    await queryClient.cancelQueries(useWishlistQueryKey);
  },
  onSettled: () => {
    queryClient.invalidateQueries(useAccountIdQueryKey);
    queryClient.invalidateQueries(useCartQueryKey);
    queryClient.invalidateQueries(useWishlistQueryKey);
    onSettled();
  },
});

// eslint-disable-next-line import/prefer-default-export
export { useLogin };
