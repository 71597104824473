import React from 'react';

import { Link } from 'gatsby';

import t from '../lib/translate';
import { useAccountId } from '../lib/service/account/useAccountId';

import Layout from '../layouts/default';
import Helmet from '../components/helmet';
import Section from '../component-elements/content-section';
import ContentHeaderCms from '../content-elements/contentHeaderCms';
import LoginForm from '../components/shop-account/login-form';

import * as style from './component.module.scss';

const LoginPage = () => {
  const account = useAccountId();

  return (
    <Layout>
      <Helmet
        titleFallback={t('login_title')}
        metaRobots="noindex-follow"
      />
      <ContentHeaderCms>
        <h1>{t`login_title`}</h1>
      </ContentHeaderCms>
      <Section background="Grau mit Ring">
        <div className="col-md-8 content-col">
          <h5>{t`login_section_title`}</h5>
          <p>{t`login_section_text`}</p>
        </div>
        <div className="col-md-8 content-col">
          {!account.data && (
            <LoginForm
              theme="light"
              onLoggedIn={() => {
                window.location.href = `${process.env.GATSBY_ACCOUNT_URL}`;
              }}
            />
          )}
          {account.data && (
            <>
              <div className={style.success}>
                {t`login_section_success`}
              </div>
              <Link to="/" className="btn btn-primary">
                {t`login_button_continue`}
              </Link>
            </>
          )}
        </div>
      </Section>
      <Section background="Weiß">
        <div className="col-md-10 content-col">
          <h5>{t`login_section_subscribe_title`}</h5>
          <p>{t`login_section_subscribe_text`}</p>
        </div>
        <div className="col-md-4 offset-md-2 content-col d-flex align-items-center">
          <Link to="/customer/account/create" className="btn btn-secondary btn-block">
            {t`login_section_subscribe_button_label`}
          </Link>
        </div>
      </Section>
    </Layout>
  );
};

export default LoginPage;
