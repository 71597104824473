import CartCache from './cache';
import { createCart } from './createCart';
import { request } from './request';

const cart = new CartCache();

const query = `mutation($sourceId: String!, $destinationId: String!) {
  mergeCarts(
    source_cart_id: $sourceId
    destination_cart_id: $destinationId
  ) {
    id
  }
}`;

const mergeGuestCart = async (accountId) => {
  if (!accountId) {
    throw new Error('cart merge needs an account-id');
  }

  const oldCartId = await cart.getId();

  await cart.clear();
  if (!oldCartId) {
    return;
  }

  const newCartId = await createCart();

  await request(
    query,
    {
      sourceId: oldCartId,
      destinationId: newCartId,
    },
    accountId,
  );
};

// eslint-disable-next-line import/prefer-default-export
export { mergeGuestCart };
