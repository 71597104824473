import AccountCache from './cache';
import { mergeGuestCart } from '../../cart/api/merge';
import {
  LoginAlreadyLoggedinError, LoginProcessError,
  LoginRequestError, LoginResponseError, LoginUnauthorizedError,
} from './errors';

const baseUrl = () => `${process.env.GATSBY_SERVICE_ACCOUNT_REST_BASEURL}/${process.env.GATSBY_SERVICE_ACCOUNT_STORECODE}`;

const accountCache = new AccountCache();

const login = async (username, password) => {
  if (await accountCache.getId()) {
    throw new LoginAlreadyLoggedinError();
  }

  const tokenResponse = await fetch(
    new Request(`${baseUrl()}/V1/integration/customer/token`), {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/json' }),
      body: JSON.stringify({
        username,
        password,
      }),
    },
  );

  if (tokenResponse.status === 401) {
    let err;
    try {
      err = await tokenResponse.json();
    } catch (e) {
      throw new LoginResponseError(`login 401 response body not readable: ${e.message}`);
    }
    throw new LoginUnauthorizedError(err.message);
  }

  if (!tokenResponse.ok || tokenResponse.status !== 200) {
    throw new LoginRequestError('account service request error');
  }

  let accountId;
  try {
    accountId = await tokenResponse.text();
  } catch (e) {
    throw new LoginResponseError(`login 200 response body not readable: ${e.message}`);
  }
  accountId = accountId.replace(/"/g, '');

  try {
    await accountCache.setId(accountId);
  } catch (e) {
    throw new LoginProcessError(`saving account id failed: ${e.message}`);
  }

  try {
    await mergeGuestCart(accountId);
  } catch (e) {
    // ignore error
  }

  return accountId;
};

// eslint-disable-next-line import/prefer-default-export
export { login };
